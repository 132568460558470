import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import PageWrapper from "../components/PageWrapper"

const Styled404Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 50px;
  p {
    color: black;
  }
  h3 {
    margin-top: 100px;
    color: black;
  }
`

const errorPage: React.FC = () => {
  const seo = {
    seoTitle: "404",
    seoDescription: "sidan finns inte",
    slug: "404",
  }
  return (
    <PageWrapper indexing={false} seo={seo}>
      <Styled404Container>
        <h3>This page does not exist</h3>
        <p>
          You have tried to access a page that no longer exists. Click
          <Link to="/">here</Link> to return to home page.
        </p>
      </Styled404Container>
    </PageWrapper>
  )
}

export default errorPage
